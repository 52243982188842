@use "./_variables"
:root 
    --primary-bg: var(--primary-dark)
    // --primary-bg:rgb(13, 110, 253)
    // --brand-text-color: #f6fff7
    // --gradient-color-1: var(--primary-bg)
    // --gradient-color-2: #1968A3
    // --gradient-color-3: #63a1d1

    color-scheme: light

.primary-bg
    background: var(--primary-color)

.brand-text
    // color: var(--brand-text-color) !important
    color: white !important

.no-dec
    text-decoration: none

.brand-gradient
    background: var(--primary-bg)
    background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 78.77%, var(--gradient-color-3) 90%, var(--gradient-color-4) 100%) 

// Primereact Styling


.p-selectbutton
    .p-button:not(.p-highlight)
        background: #ffffff00 

.p-dropdown
    &:focus
        border-color: var(--primary-base)
    .p-dropdown-filter
        &:focus
            border-color: var(--primary-base)
        .p-inputtext
            &:focus
                border-color: var(--primary-base)

    // .p-autocomplete
    //     // background: var(--primary-darken)
    //     .p-button
    //         background: var(--primary-darken)
        

    // .p-autocomplete-item
    //     max-height: 25px
    //     background: var(--primary-lighter) !important    

.p-inputtext
    &:focus
        border-color: var(--primary-base) !important

.p-button
    box-shadow: var(--subtle-shadow)
    background: var(--primary-darken)
    &:hover
        background: var(--primary-base) !important
    &.p-highlight
        background: var(--primary-darken) !important

.p-inputswitch-checked
    .p-inputswitch-slider
        background: var(--primary-base) !important

.p-autocomplete-item
    // max-height: 25px
    &:hover
        background: var(--surface-200) !important

.p-dropdown-items
    .p-dropdown-item
        &:hover
            background: var(--primary-base) !important
            color: var(--text-color-light-primary) !important

.p-dropdown-filter
    .p-inputtext
        &:focus
            border-color: var(--primary-base) !important

.p-inputwrapper-focus
    border-color: var(--primary-base) !important

.p-dropdown-filter-container
    .p-dropdown-filter
        &:focus
            border-color: var(--primary-base) 

.p-toast-message-success
    background: var(--primary-base) !important
    background:  linear-gradient(9deg, var(--primary-darkest) 0%, var(--primary-darken) 35%, var(--primary-base) 65%, var(--primary-lighter) 100%) !important
    border-color: var(--brand-success) !important