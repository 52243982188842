.table-status-panel {
    min-width: 300px;
    max-height: 400px;
    overflow-y: auto;
}

.table-status-list {
    padding: 1rem;
}

.table-status-list h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: var(--text-color);
}

.table-status-item {
    display: grid;
    grid-template-columns: 2fr 30px 2fr;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--surface-border);
}

.table-name {
    font-weight: 600;
}

.table-status {
    text-align: center;
}

.table-time {
    font-size: 0.9rem;
    color: var(--text-color-secondary);
}

/* Style for the status button in the navbar */
.status-button {
    background: transparent !important;
    border: none !important;
    padding: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.status-button:hover {
    background: rgba(255, 255, 255, 0.1) !important;
}

.status-button:focus {
    box-shadow: none !important;
} 